import React, { Component } from "react";

import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Loader from "react-loader-spinner";

import InlineText from "../components/inline_text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  background: #f8f8f8;
  min-height: 100vh;
`;

const BlockContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  & a {
    color: black;
    text-decoration: none;
  }
  margin: 30px 0;
  font-size: 1em;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Button = styled.button`
  background: ${props => (props.primary ? "white" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "1em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  padding: 0.65em 1em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;
  width: 270px;
  background: transparent;
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
  }
  &:focus {
    outline: none;
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      login_loading: false
    };
  }

  updateUser = (key, value) => {
    const { user } = this.state;
    user[key] = value;
    this.setState({ user: user });
  };

  signin = () => {
    const { user } = this.state;
    this.setState({ login_loading: true });
    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "login.json";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json"
      },
      body: JSON.stringify({
        user: user
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500 || result.status === 401) {
          this.setState({ error: result, login_loading: false });
        } else {
          this.setState(
            {
              user: result.user,
              public_link: result.public_link,
              login_loading: false
            },
            () =>
              window.localStorage.setItem(
                "viewingroom",
                JSON.stringify(this.state)
              )
          );
          this.props.history.push("/office");
        }
      })
      .catch(error => this.setState({ error: error, login_loading: false }));
  };

  render() {
    const { error, user, login_loading } = this.state;
    return (
      <Container>
        <Header>
          <Title>
            <Link to="/">viewingroom.space</Link>
          </Title>
        </Header>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.signin();
          }}
        >
          <BlockContainer>
            <InlineText
              type="email"
              value={user.email || ""}
              placeholder={"Email"}
              onChange={e => this.updateUser("email", e.target.value)}
              required
              filled={user.email}
              light
            />
            <InlineText
              type="password"
              value={user.password || ""}
              placeholder={"Password"}
              onChange={e => this.updateUser("password", e.target.value)}
              required
              filled={user.password}
              light
            />
          </BlockContainer>
          {error && (
            <p>
              {error.error
                ? error.error
                : "Something went wrong, please try again"}
            </p>
          )}
          <div style={{ height: 70, display: "flex", alignItems: "center" }}>
            {login_loading ? (
              <Loader type="Bars" color="#000" height={40} width={80} />
            ) : (
              <Button primary border type="submit">
                Login
              </Button>
            )}
          </div>{" "}
        </form>
      </Container>
    );
  }
}

export default withRouter(Login);
