import React, { useLayoutEffect } from "react";

import "./App.css";

import Home from "./containers/home";
import Pricing from "./containers/pricing";
import Office from "./containers/office";
import Login from "./containers/login";
import PublicViewingRoom from "./containers/public_viewing_room";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

function AuthRoute({ children, ...rest }) {
  const local = JSON.parse(window.localStorage.getItem("viewingroom"));
  let auth = local && local.user.token ? true : false;
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function HomeRoute({ children, ...rest }) {
  const local = JSON.parse(window.localStorage.getItem("viewingroom"));
  let auth = local && local.user.token ? true : false;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/office",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  let is_usd = false;
  if (
    window.Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.includes("America")
  ) {
    is_usd = true;
  }

  return (
    <Router>
      <Switch>
        <AuthRoute path="/office">
          <Office />
        </AuthRoute>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/pricing">
          <Pricing is_usd={is_usd} />
        </Route>
        <Route
          path="/a/:token"
          render={props => <PublicViewingRoom {...props} />}
        />

        <HomeRoute path="/">
          <Home is_usd={is_usd} />
        </HomeRoute>
      </Switch>
    </Router>
  );
}

export default App;
