import React, { Component } from "react";

import styled from "styled-components";
import ReactFilestack from "filestack-react";
import Slider from "@material-ui/core/Slider";
import Modal from "react-modal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "react-loader-spinner";

import InlineText from "./inline_text";

import Mobile from "../assets/mobile.svg";
import Scale from "../assets/scale.svg";
import Close from "../assets/close.svg";

export function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
}

const Button = styled.a`
  background: ${props => (props.primary ? "white" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "0.8em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  margin: ${props => (props.border ? "1em 0" : "1em")};
  padding: 0.55em 0.8em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
  }
  &:focus {
    outline: none;
  }
`;

const StyledReactModal = styled(ReactModalAdapter).attrs({
  modalClassName: "Modal"
})`
  & .ReactModal__Overlay {background-color: rgba(0, 0, 0, 0.7) !important;}
  & .Modal {
    position: absolute;
    top: 20%;
    left: 50%;
    right: 50%;
    bottom: 0%;
    margin-right: -50%;
    transform: translate(-50%, -15%);
    background: white;
    border-radius: 3px;
    padding: 20px;
    flex-direction: column;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    @media (max-width: 768px) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      margin: 0;
      transform: none;
    };
    &:focus {
      outline: none;
    }
  }
}`;

const CloseBtn = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  overflow: scroll;
  padding: 30px;
  overflow-wrap: anywhere;
  @media (max-width: 414px) {
    padding: 15px;
  }
`;

const Preview = styled.div`
  background: white;
  position: relative;
  opacity: 1;
  padding: 30px 50px 50px 50px;

  overflow: scroll;
  ${props =>
    !props.public_room &&
    "border-radius: 8px; box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);"}
  ${props =>
    props.selected === "dark_mode" &&
    `background: #000; opacity: 1; color: white;`};
  ${props => props.mobile_view && "width: 414px; margin: auto; padding: 20px;"}
  @media (max-width: 414px) {
    padding: 20px;
  }
`;

const CoverPicture = styled.div`
  width: 100%;
  height: 320px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${props => (props.public_room ? "0" : "6px 6px 0px 0px")};
  ${props =>
    props.cover_picture_url &&
    `background-image: url(${props.cover_picture_url});`}
`;

const ArtworkPicture = styled.div`
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  min-height: 160px;
  ${props => props.empty && props.public_room && "background-color: #f8f8f8;"}
  & button {
    position: absolute;
    font-family: "Akkurat Light";
  }
`;

const LogoContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
  & button {
    position: absolute;
    font-family: "Akkurat Light";
  }
`;

const LogoWrapper = styled.div`
  width: ${props => (props.logo_width ? props.logo_width + "px" : "100px")};
`;

const InlineTextArea = styled.textarea`
  margin-top: 40px;
  border: none;
  font-size: 20px;
  padding: 5px;
  width: 100%;
  font-family: inherit;
  &::placeholder {
    color: #dddddd;
  };
  &:focus {
    outline: 5px solid #fff8e1;
  }
  &:hover {
    background: #f6f9fc;
    ${props =>
      props.selected_template === "dark_mode" && "background: #494a4b;"}
  }
  ${props => props.is_title && "font-size: 30px;"}
  ${props =>
    props.selected_template === "dark_mode" &&
    "background: #000; color: white;"}
  ${props => props.filled && "background: none;"}

`;

const InlineTextAreaPublic = styled.div`
  margin-top: 30px;
  font-size: 16px;
  width: 100%;
  font-family: inherit;

  ${props =>
    props.selected_template === "dark_mode" &&
    "background: #000; color: white;"}
`;

const MobileView = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  @media (max-width: 376px) {
    display: none;
  }
  ${props => props.mobile_view && "opacity: 0.5;"}
`;

const PictureButton = styled.button`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: inherit;
  font-family: "Akkurat Light";
  ${props => !props.picture && "border: 1px dotted;"}
  ${props => props.picture && "display: flex;"}
  ${props =>
    props.logo &&
    "width: 100px; height: 50px; font-size: 12px; text-decoration: underline; "};
  ${props =>
    props.logo &&
    props.picture &&
    "width: 100%; display: flex; align-items: center;"}
  ${props => props.selected_template === "dark_mode" && "color: white;"}
`;

const ArtworkContainer = styled.div`
  width: 48%;
  margin-top: 50px;
  position: relative;
  ${props => props.public_room && "max-width: 390px; margin-right: 50px;"}
  & input {
    margin: 0;
    font-size: 14px;
    width: 80%;
  }
  @media (max-width: 768px) {
    ${props => props.public_room && "max-width: 290px;"}
  }
  @media (max-width: 414px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
  & p {
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid;
    width: 38px;
  }
  ${props => props.mobile_view && "width: 100%;"}
`;

const ArtworksList = styled.div`
  display: flex;
  justify-content: ${props =>
    props.public_room ? "flex-start" : "space-between"};
  flex-wrap: wrap;

  @media (max-width: 414px) {
    flex-direction: column;
  }
`;

const AddArtwork = styled.div`
  width: 48%;
  height: 280px;
  opacity: 1;
  margin-top: ${props => (props.office ? "50px" : "48px")};
  border: 3px solid #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Akkurat Light";
  &:hover {
    background: #f8f8f8;
  }
  @media (max-width: 414px) {
    width: 98%;
    margin-top: 25px;
  }
  ${props => props.mobile_view && "width: 100%;"}
`;

const ArtworkHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SliderWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  & img {
    margin-right: 10px;
  }
`;

const Currency = styled.div`
  margin-right: 2;
  margin-top: 5;
`;

const selectStyle = {
  fontFamily: "inherit"
};

class VRForm extends Component {
  constructor(props) {
    super(props);
    this.state = { mobile_view: false, show_description: false };
  }

  openDescriptionModal = () => {
    this.setState({ show_description: true });
  };

  onRequestClose = () => {
    this.setState({ show_description: false });
  };

  render() {
    const { mobile_view, show_description } = this.state;

    const {
      artworks,
      addArtwork,
      project_share,
      project,
      entity,
      updateArtwork,
      updateProject,
      updateProjectShare,
      updateEntity,
      deleteArtwork,
      Wrapper,
      office,
      update_loading,
      public_room,
      new_artwork_loading
    } = this.props;

    const currency_display = artwork => {
      let currencies = [
        { key: "EUR", value: "€" },
        { key: "USD", value: "$" },
        { key: "GBP", value: "£" },
        { key: "CHF", value: "CHF" }
      ];
      if (
        artwork.price_cents &&
        artwork.price_cents !== 0 &&
        project_share.show_price
      ) {
        return (
          <Currency>
            {currencies.find(c => c.key === artwork.price_currency).value}
          </Currency>
        );
      }
    };

    const project_description =
      project.description && project.description.split(" ").length > 100
        ? project.description
            .split(" ", 100)
            .concat(" ...")
            .join(" ")
        : project.description;
    return (
      <Wrapper>
        {!public_room && (
          <div
            style={
              office
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline"
                  }
                : {}
            }
          >
            {office && (
              <p style={{ fontSize: 12, margin: "10px 0 0", opacity: 0.5 }}>
                {update_loading ? "Saving..." : "Saved!"}
              </p>
            )}
            <MobileView
              onClick={() =>
                this.setState({ mobile_view: !this.state.mobile_view })
              }
              mobile_view={mobile_view}
            >
              <img src={Mobile} width="20px" alt="mobile view" />
            </MobileView>
          </div>
        )}
        <Preview
          selected={project_share.template}
          mobile_view={mobile_view}
          innerHeight={window.innerHeight}
          public_room={public_room}
        >
          {project_share.template === "cover_picture" && (
            <>
              <div style={{ height: 350 }} />
              <CoverPicture
                cover_picture_url={project_share.cover_picture_url}
                public_room={public_room}
              >
                {!public_room && (
                  <ReactFilestack
                    apikey={process.env.REACT_APP_FILESTACK_API_KEY}
                    customRender={({ onPick }) => (
                      <PictureButton
                        onClick={onPick}
                        picture={project_share.cover_picture_url}
                      >
                        {project_share.cover_picture_url ? "Edit" : "Add"} Cover
                        Picture
                      </PictureButton>
                    )}
                    onSuccess={res =>
                      updateProjectShare(
                        "cover_picture_url",
                        res.filesUploaded[0].url
                      )
                    }
                  />
                )}
              </CoverPicture>
            </>
          )}

          <LogoContainer>
            <ReactFilestack
              apikey={process.env.REACT_APP_FILESTACK_API_KEY}
              customRender={({ onPick }) => (
                <>
                  {entity.logo_url && (
                    <LogoWrapper logo_width={project_share.logo_width}>
                      <img src={entity.logo_url} width="100%" alt="" />
                    </LogoWrapper>
                  )}
                  {public_room && !entity.logo_url && <h1>{entity.name}</h1>}
                  {!public_room && (
                    <PictureButton
                      onClick={onPick}
                      picture={entity.logo_url}
                      logo
                    >
                      {entity.logo_url ? "Edit" : "Add"} your logo
                      {entity.logo_url && (
                        <SliderWrapper>
                          <img src={Scale} width="18px" alt="size" />
                          <Slider
                            value={parseInt(project_share.logo_width) || 100}
                            min={30}
                            max={300}
                            step={10}
                            onChange={(e, v) =>
                              updateProjectShare("logo_width", v)
                            }
                            aria-labelledby="continuous-slider"
                            style={{ width: 100 }}
                          />
                        </SliderWrapper>
                      )}
                    </PictureButton>
                  )}
                </>
              )}
              onSuccess={res =>
                updateEntity("logo_url", res.filesUploaded[0].url)
              }
            />
          </LogoContainer>

          <form onSubmit={e => console.log(e)}>
            <InlineText
              type="text"
              value={project.title || ""}
              placeholder={"Room Title"}
              onChange={e => updateProject("title", e.target.value)}
              required
              is_title
              selected_template={project_share.template}
              filled={project.title}
              public_room={public_room}
            />
            {public_room ? (
              <div style={{ marginBottom: 30 }}>
                <InlineTextAreaPublic
                  selected_template={project_share.template}
                >
                  {project.description &&
                    project_description.split("\n").map(function(item, index) {
                      return (
                        <span key={index}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </InlineTextAreaPublic>
                {project.description &&
                  project.description.split(" ").length > 100 && (
                    <p
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={this.openDescriptionModal}
                    >
                      Read More
                    </p>
                  )}
                <StyledReactModal
                  isOpen={show_description}
                  onRequestClose={this.onRequestClose}
                  ariaHideApp={false}
                >
                  <CloseBtn
                    src={Close}
                    width="20px"
                    onClick={this.onRequestClose}
                    alt="X"
                  />
                  <ModalContent>
                    {project.description &&
                      project.description
                        .split("\n")
                        .map(function(item, index) {
                          return (
                            <span key={index}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                  </ModalContent>
                </StyledReactModal>
              </div>
            ) : (
              <InlineTextArea
                rows={4}
                type="text"
                value={project.description || ""}
                placeholder={"Room Description"}
                onChange={e => updateProject("description", e.target.value)}
                required
                selected_template={project_share.template}
                filled={project.description}
                public_room={public_room}
              />
            )}
            <ArtworksList public_room={public_room}>
              {artworks.map((artwork, index) => {
                return (
                  <ArtworkContainer
                    key={index}
                    mobile_view={mobile_view}
                    public_room={public_room}
                  >
                    {!office && !public_room && (
                      <ArtworkHeader>
                        <span style={{ opacity: 0.4, fontSize: 12 }}>
                          Artwork {artwork.id}
                        </span>
                      </ArtworkHeader>
                    )}
                    <ArtworkPicture
                      empty={!artwork.picture_url}
                      public_room={public_room}
                    >
                      {!public_room && (
                        <ReactFilestack
                          apikey={process.env.REACT_APP_FILESTACK_API_KEY}
                          customRender={({ onPick }) => (
                            <PictureButton
                              onClick={onPick}
                              picture={artwork.picture_url}
                              selected_template={project_share.template}
                            >
                              {artwork.picture_url ? "Edit" : "Add"} Artwork
                              picture
                            </PictureButton>
                          )}
                          onSuccess={res =>
                            updateArtwork(
                              office ? artwork.id : artwork.local_index,
                              "picture_url",
                              res.filesUploaded[0].url
                            )
                          }
                        />
                      )}
                      <img src={artwork.picture_url} width="100%" alt="" />
                    </ArtworkPicture>
                    {[
                      { key: "Artist Name", value: "artist_name" },
                      { key: "Title", value: "title" },
                      { key: "Year", value: "year" },
                      { key: "Medium", value: "medium" },
                      { key: "Price", value: "price" }
                    ].map((info, info_index) => {
                      return (
                        <div
                          key={info_index}
                          style={
                            info.value === "price"
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 5
                                }
                              : {}
                          }
                        >
                          {info.value === "price" && !public_room && (
                            <Select
                              value={artwork.price_currency || "EUR"}
                              onChange={e =>
                                updateArtwork(
                                  office ? artwork.id : artwork.local_index,
                                  "price_currency",
                                  e.target.value
                                )
                              }
                              style={selectStyle}
                            >
                              <MenuItem value={"EUR"}>€</MenuItem>
                              <MenuItem value={"USD"}>$</MenuItem>
                              <MenuItem value={"GBP"}>£</MenuItem>
                              <MenuItem value={"CHF"}>CHF</MenuItem>
                            </Select>
                          )}
                          {info.value === "price" && public_room && (
                            <span>{currency_display(artwork)}</span>
                          )}
                          <InlineText
                            type="text"
                            value={
                              info.value === "price"
                                ? public_room
                                  ? (
                                      artwork["price_cents"] / 100
                                    ).toLocaleString()
                                  : artwork["price_cents"] / 100
                                : artwork[info.value]
                            }
                            placeholder={info.key}
                            onChange={e =>
                              updateArtwork(
                                office ? artwork.id : artwork.local_index,
                                info.value,
                                e.target.value
                              )
                            }
                            required
                            selected_template={project_share.template}
                            kind={info.value}
                            filled={
                              info.value === "price"
                                ? artwork["price_cents"]
                                : artwork[info.value]
                            }
                            public_room={public_room}
                            show_price={project_share.show_price}
                            light={info.value !== "artist_name"}
                            style={
                              info.value === "price"
                                ? public_room
                                  ? !artwork["price_cents"] ||
                                    artwork["price_cents"] === 0
                                    ? { display: "none" }
                                    : { marginTop: 0 }
                                  : { marginTop: 2 }
                                : {}
                            }
                          />
                        </div>
                      );
                    })}
                    {!public_room && (
                      <p
                        onClick={deleteArtwork.bind(
                          this,
                          office ? artwork.id : artwork.local_index
                        )}
                      >
                        Delete
                      </p>
                    )}
                    {public_room && project_share.show_inquire && (
                      <div style={{ marginTop: 20 }}>
                        <Button
                          primary
                          border
                          href={`mailto:${entity.email}?subject=From viewingroom.space: ${artwork.artist_name} - ${artwork.title}`}
                        >
                          INQUIRE
                        </Button>
                      </div>
                    )}
                  </ArtworkContainer>
                );
              })}
              {!public_room && (
                <AddArtwork
                  onClick={() => !new_artwork_loading && addArtwork()}
                  mobile_view={mobile_view}
                  office={office}
                >
                  {new_artwork_loading && office ? (
                    <Loader type="Bars" color="#000" height={40} width={80} />
                  ) : (
                    "+ Add Artwork"
                  )}
                </AddArtwork>
              )}
            </ArtworksList>
          </form>
        </Preview>
      </Wrapper>
    );
  }
}

export default VRForm;
