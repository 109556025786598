import React, { useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import Check from "../assets/check.svg";
import { GApageView } from "../index";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
`;

const Title = styled.h1`
  & a {
    color: black;
    text-decoration: none;
  }
  margin: 30px 0;
  font-size: 1em;
  border-bottom: 1px dotted;
  padding-bottom: 5px;
`;

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Prices = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  max-width: 850px;
  @media (max-width: 414px) {
    flex-direction: column;
  }
`;

const Price = styled.div`
  padding: 20px;
  flex: 1;
  max-width: 200px;
  background: #f6f9fc;
  @media (max-width: 414px) {
    margin-top: 10px;
  }
  & p {
    margin: 5px;
  }
`;

const Kind = styled.p`
  background: #fff8e1;
  width: fit-content;
  font-size: 16px;
`;
const Money = styled.h4`
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: bold;
  & span {
    font-size: 10px;
  }
`;

const Button = styled.button`
  background: ${props => (props.primary ? "white" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "1em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  margin: ${props => (props.border ? "1em 0" : "1em")};
  padding: 0.65em 1em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;
  width: 225px;
  margin: 50px 0;
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
  }
  &:focus {
    outline: none;
  }
`;

function Pricing(props) {
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push("/"), [history]);
  useEffect(() => {
    GApageView("Pricing");
  }, []);

  return (
    <Container>
      <Title>
        <Link to="/">viewingroom.space</Link>
      </Title>
      <PricingContainer>
        <h2>Pricing</h2>
        <p>
          15 days free trial. <br /> Cancel anytime.
        </p>
        <Prices>
          <Price>
            <Kind>SINGLE</Kind>
            <Money>
              {props.is_usd ? "$" : "€"}19<span> per month</span>
            </Money>
            <p>
              <img src={Check} width="12px" alt="-" /> 1 viewing room
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> 1 collaborator
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited artworks
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited shares
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited inquieries
            </p>
          </Price>
          <Price>
            <Kind>PRO</Kind>
            <Money>
              {props.is_usd ? "$" : "€"}39<span> per month</span>
            </Money>
            <p>
              <img src={Check} width="12px" alt="-" /> 10 viewing room
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited collaborator
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited artworks
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited shares
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited inquieries
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Analytics
            </p>
          </Price>
          <Price>
            <Kind>POWER 100</Kind>
            <Money>
              {props.is_usd ? "$" : "€"}69<span> per month</span>
            </Money>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited viewing room
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited collaborator
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited artworks
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited shares
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Unlimited inquieries
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Analytics
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Your art fair platform
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Premium support
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Custom templates
            </p>
            <p>
              <img src={Check} width="12px" alt="-" /> Advanced sales tools
            </p>
          </Price>
        </Prices>
      </PricingContainer>
      <Button primary border onClick={handleOnClick}>
        Start your viewing room
      </Button>
      <p>
        Powered by{" "}
        <a href="https://tropy.co" target="_blank" rel="noopener noreferrer">
          Tropy.co
        </a>
      </p>
    </Container>
  );
}

export default Pricing;
