import React, { useState, useEffect, useRef } from "react";

import { withRouter } from "react-router";

import { Link } from "react-router-dom";

import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import ViewingRoomBuilder from "./viewing_room_builder";
import ArrowUpRight from "../assets/arrow-up-right.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  background: #f8f8f8;
  min-height: 100vh;
  @media (max-width: 414px) {
    padding: 0 15px;
  }
`;

const Title = styled.h1`
  & a {
    color: black;
    text-decoration: none;
  }
  margin: 30px 0;
  font-size: 1em;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Button = styled.button`
  background: ${props => (props.primary ? "white" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "1em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  padding: 0.65em 1em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;
  width: 270px;
  background: transparent;
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
  }
  &:focus {
    outline: none;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 40px 0 90px;
  & a {
    display: flex;
    align-items: flex-end;
    color: black;
    margin-top: 20px;
    font-size: 14px;
  }
`;

const UserIcon = styled.div`
  background: #313131;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
`;

function Office(props) {
  const [copy, setCopy] = useState(false);
  const [public_link, setPublicLink] = useState(
    JSON.parse(window.localStorage.getItem("viewingroom")) &&
      JSON.parse(window.localStorage.getItem("viewingroom")).public_link
  );
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    if (copy) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        copy === true ? setCopy(false) : setCopy(true);
      }, 3000);
    }
  }, [copy]);

  const logout = props => {
    window.localStorage.removeItem("viewingroom");
    props.history.push("/");
  };

  const updatePublicLink = public_link => {
    setPublicLink(public_link);
  };

  const user =
    JSON.parse(window.localStorage.getItem("viewingroom")) &&
    JSON.parse(window.localStorage.getItem("viewingroom")).user;

  return (
    <Container>
      <Header>
        <Title>
          <Link to="/">viewingroom.space</Link>
        </Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          <UserIcon>
            {user.first_name && user.first_name.substring(0, 1)}
            {user.last_name && user.last_name.substring(0, 1)}
          </UserIcon>
          <p style={{ cursor: "pointer" }} onClick={() => logout(props)}>
            Sign out
          </p>
        </div>
      </Header>
      {public_link && (
        <LinkContainer>
          <h3>Share your Viewing Room link</h3>
          <div>
            <CopyToClipboard
              text={public_link}
              onCopy={() => setCopy(true)}
              style={{
                wordBreak: "break-all",
                background: "#FFF8E1",
                cursor: "pointer"
              }}
            >
              <h3>{public_link.split("//").pop()}</h3>
            </CopyToClipboard>
          </div>
          <CopyToClipboard text={public_link} onCopy={() => setCopy(true)}>
            <Button primary border>
              {copy ? "COPIED !" : "COPY VIEWING ROOM LINK"}
            </Button>
          </CopyToClipboard>
          <a href={public_link} target="_blank" rel="noopener noreferrer">
            Preview
            <img
              src={ArrowUpRight}
              width="13px"
              style={{ marginBottom: 2, marginLeft: 3 }}
              alt=""
            />
          </a>
        </LinkContainer>
      )}
      <ViewingRoomBuilder office updatePublicLink={updatePublicLink} />
    </Container>
  );
}

export default withRouter(Office);
