import React from "react";

import styled, { keyframes, css } from "styled-components";

import Modal from "./get_link_modal";

import { GAevent } from "../index";

const Container = styled.div`
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 70px;
  width: 100%;
  ${props => props.sticky && "position: fixed;"}
  ${props => props.hide && "display: none;"}
  @media (max-width: 414px) {
    background: white;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const colorama = keyframes`
  0% {
    background: white;
  }

  50% {
    background: #FFEB3B;
  }

  100% {
    background: white;
  }
`;

const Button = styled.button`
  background: ${props => (props.primary ? "white" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "1em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  margin: ${props => (props.border ? "1em 0" : "1em")};
  padding: 0.65em 1em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;

  ${props => props.primary && "margin-left: 100px; width: 230px;"}
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
    ${props =>
      props.primary &&
      css`
        animation: ${colorama} 4s linear infinite;
      `};
  }
  &:focus {
    outline: none;
  }
    @media (max-width: 414px) {
      ${props =>
        props.primary &&
        "margin-left: 1em; width: 85%; box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);"}
      background: ${props => (props.primary ? "#313131" : "none")};
      color: white;

`;

function Footer(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    GAevent("Register", "Click Get Link");
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Container sticky={props.sticky} hide={modalIsOpen}>
      <Button primary onClick={openModal}>
        GET LINK
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        user={props.user}
        entity={props.entity}
        project_share={props.project_share}
        updateUser={props.updateUser}
        updateEntity={props.updateEntity}
        updateProjectShare={props.updateProjectShare}
        Button={Button}
        register={props.register}
        errors={props.errors}
        public_link={props.public_link}
        register_loading={props.register_loading}
      />
    </Container>
  );
}

export default Footer;
