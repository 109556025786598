import React, { useLayoutEffect, useRef, useEffect, useState } from "react";

import { withRouter } from "react-router";

import ViewingRoomBuilder from "./viewing_room_builder";
import Modal from "react-modal";

import background from "../assets/viewingroom.jpg";
import { GApageView } from "../index";

import styled from "styled-components";
import { Link } from "react-router-dom";

import Close from "../assets/close.svg";

export function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
}

const AppContainer = styled.div`
  position: relative;
  background: #f8f8f8;
`;

const Title = styled.h1`
  color: white;
  border: 8px solid white;
  padding: 32vh 4vw;
  margin: 0px;
  @media (min-width: 768px) {
    padding: 20%;
    font-size: 2.5em;
  }
`;

const Hero = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: center 40%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 414px) {
    min-height: 95vh;
    & h1 {
      font-size: 1.5em;
    }
  }
`;

const Commercial = styled.div`
  padding: 60px 100px;
  text-align: left;

  transform: translateX(${({ animate }) => (animate ? "0" : "-100vw")});
  transition: transform 1s;

  & h3 {
    font-size: 50px;
    font-weight: bold;
    margin: 20px 0;
  }
  & p {
    margin: 40px 0 20px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 20px;
    & h3 {
      font-size: 30px;
      margin: 10px 0;
    }
    & p {
      margin: 30px 0 10px 0;
      font-size: 12px;
    }
  }
`;

const Legal = styled.p`
  font-weight: 500;
  & a {
    color: black;
  }
`;

const Header = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 30px;
  color: white;
  & p {
    font-family: "Akkurat Bold";
  }
`;

const StyledReactModal = styled(ReactModalAdapter).attrs({
  modalClassName: "Modal"
})`
  & .ReactModal__Overlay {background-color: rgba(0, 0, 0, 0.7) !important;}
  & .Modal {
    position: absolute;
    top: 20%;
    left: 50%;
    right: 50%;
    bottom: 0%;
    margin-right: -50%;
    transform: translate(-50%, -15%);
    background: white;
    border-radius: 3px;
    padding: 20px;
    flex-direction: column;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background: #f8f8f8;
    max-width: 450px;
    max-height: 450px;
    @media (max-width: 768px) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      margin: 0;
      transform: none;
    };
    &:focus {
      outline: none;
    }
  }
}`;

const CloseBtn = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  overflow: scroll;
  padding: 30px;
  & a {
    text-decoration: none;
    border-bottom: 1px solid;
  }
  @media (max-width: 414px) {
    padding: 15px;
  }
`;

function Home(props) {
  const [show, doShow] = useState({
    commercialItem: false,
    stickyFooter: false,
    infoModal: false
  });
  const ourRef = useRef(null);

  function openInfoModal() {
    doShow(state => ({ ...state, infoModal: true }));
  }
  function closeInfoModal() {
    doShow(state => ({ ...state, infoModal: false }));
  }

  useEffect(() => {
    GApageView("Home");
  }, []);

  useLayoutEffect(() => {
    const topPos = element => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;
      if (div1Pos < scrollPos) {
        doShow(state => ({ ...state, commercialItem: true }));
      }
      if (scrollPos > document.body.offsetHeight - 500) {
        doShow(state => ({ ...state, stickyFooter: true }));
      }
      if (scrollPos < 1000) {
        doShow(state => ({ ...state, stickyFooter: false }));
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <AppContainer>
      <Hero background={background}>
        <Header>
          <p style={{ cursor: "pointer" }} onClick={() => openInfoModal()}>
            Info
          </p>
          <p
            style={{ cursor: "pointer", marginLeft: 15 }}
            onClick={() => props.history.push("/login")}
          >
            Login
          </p>
          <StyledReactModal
            isOpen={show.infoModal}
            onRequestClose={() => closeInfoModal()}
            ariaHideApp={false}
          >
            <CloseBtn
              src={Close}
              width="20px"
              onClick={() => closeInfoModal()}
              alt="X"
            />
            <ModalContent>
              <p style={{ fontFamily: "Akkurat Bold", fontSize: "1.1em" }}>
                The easiest way to publish and share your own viewing room.
              </p>
              <p>
                A project from{" "}
                <a
                  href="https://tropy.co"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  Tropy.co
                </a>
                , inventory solution for the art worlds.
              </p>
              <p>
                Feel free to reach us if you have any question:{" "}
                <a href="mailto:hello@viewingroom.space">
                  hello@viewingroom.space
                </a>
              </p>
            </ModalContent>
          </StyledReactModal>
        </Header>

        <Title>viewingroom.space</Title>
      </Hero>
      <Commercial animate={show.commercialItem} ref={ourRef}>
        <h3>
          Complete your online viewing room.
          <br /> Get a shareable link and manage your inquieries.
        </h3>

        <Legal>
          <Link to="/pricing" target={"_blank"}>
            15 days free trial. Starting at {props.is_usd ? "$" : "€"}19/month
            after that. Cancel anytime.
          </Link>
        </Legal>
      </Commercial>
      <ViewingRoomBuilder stickyFooter={show.stickyFooter} />
    </AppContainer>
  );
}

export default withRouter(Home);
