import React, { useState } from "react";

import styled from "styled-components";
import Loader from "react-loader-spinner";

import InlineText from "./inline_text";
import PrivacyPolicy from "./privacy_policy";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f6f6f6;
  height: 100vh;
`;

const Button = styled.button`
  background: ${props => (props.primary ? "transparent" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "1em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  margin: ${props => (props.border ? "1em 0" : "1em")};
  padding: 0.35em 0.6em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
  }
  &:focus {
    outline: none;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-items: left;
  max-width: 250px;
`;

const BlockContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  max-width: 250px;
  & input {
    width: 100%;
  }
`;

function VisitorAuth(props) {
  const [openModal, setIsOpen] = useState(false);

  if (props.require_login && !props.vr_token) {
    return (
      <Container>
        <Header>
          <h1>{props.entity.name}</h1>
          <h3 style={{ margin: 0 }}>{props.project.title}</h3>
          <p
            style={{
              marginTop: 30
            }}
          >
            Please provide your contact information to enter the viewing room:
          </p>
        </Header>

        <BlockContainer>
          <form
            onSubmit={e => {
              e.preventDefault();
              props.registerVisitor();
            }}
          >
            <InlineText
              type="text"
              value={props.visitor.first_name || ""}
              placeholder={"First Name"}
              onChange={e => props.updateVisitor("first_name", e.target.value)}
              required
              filled={props.visitor.first_name}
              light
            />
            <InlineText
              type="text"
              value={props.visitor.last_name || ""}
              placeholder={"Last Name"}
              onChange={e => props.updateVisitor("last_name", e.target.value)}
              required
              filled={props.visitor.last_name}
              light
            />
            <InlineText
              type="email"
              value={props.visitor.email || ""}
              placeholder={"Email"}
              onChange={e => props.updateVisitor("email", e.target.value)}
              required
              filled={props.visitor.email}
              light
              style={{ marginTop: 10 }}
            />
            <div style={{ height: 70, display: "flex", alignItems: "center" }}>
              {props.visitor_loading ? (
                <Loader type="Bars" color="#000" height={40} width={80} />
              ) : (
                <Button primary border type="submit">
                  Enter
                </Button>
              )}
            </div>
            <p style={{ fontSize: 12 }}>
              By sharing your details you agree to our{" "}
              <span
                onClick={() => setIsOpen(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Privacy Policy
              </span>{" "}
              and will receive {props.entity.name} newsletters.
            </p>
          </form>
        </BlockContainer>
        <PrivacyPolicy
          isOpen={openModal}
          onRequestClose={() => setIsOpen(false)}
          entity_name={props.entity.name}
        />
      </Container>
    );
  } else {
    return props.children;
  }
}

export default VisitorAuth;
