import React, { Component } from "react";

import { withRouter } from "react-router";

import styled from "styled-components";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import debounce from "lodash.debounce";

import TemplateSelector from "../components/template_selector";
import VRForm from "../components/vr_form";
import Footer from "../components/footer";

import { GAevent } from "../index";

const Container = styled.div`
  background: #f8f8f8;
  min-height: 100vh;
  padding: ${props => (props.office ? "" : "60px 100px 80px")};
  @media (max-width: 768px) {
    padding: ${props => (props.office ? "" : "20px 20px 80px")};
  }
`;

const Wrapper = styled.div`
  margin: 0;
`;

class ViewingRoomBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: { kind: "other" },
      project_share: {
        require_login: true,
        show_price: true,
        show_inquire: true,
        template: "classic"
      },
      user: {},
      entity: { entity_type: "art_gallery" },
      artworks: [{ local_index: 1 }],
      version: 1
    };
  }

  componentDidMount() {
    let previousData = JSON.parse(window.localStorage.getItem("viewingroom"));

    if (previousData && !this.props.office) {
      // CLEAN DATA IF OUTDATED
      if (previousData.version === 1) {
        this.setState({
          project: previousData.project,
          project_share: previousData.project_share,
          artworks: previousData.artworks,
          entity: previousData.entity,
          previous_data: true,
          register_loading: false
        });
      } else {
        window.localStorage.removeItem("viewingroom");
      }
    }
    if (this.props.office) {
      this.fetchData();
    }
  }

  saveData = () => {
    window.localStorage.setItem("viewingroom", JSON.stringify(this.state));
    if (this.props.office) {
      this.update();
    }
  };

  simpleSave = () => {
    window.localStorage.setItem("viewingroom", JSON.stringify(this.state));
  };
  saveRemove = id => {
    window.localStorage.setItem("viewingroom", JSON.stringify(this.state));
    if (this.props.office) {
      this.removeArtwork(id);
    }
  };

  fetchData = () => {
    this.setState({ fetch_loading: true });
    const storedData = JSON.parse(window.localStorage.getItem("viewingroom"));
    const token = storedData && storedData.user.token;
    const email = storedData && storedData.user.email;

    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "fetch_vr.json";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json",
        "X-User-Token": token,
        "X-User-Email": email
      }
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500) {
          this.setState({ errors: result.errors, fetch_loading: false });
        } else {
          this.setState(result, () => this.simpleSave());
          this.props.updatePublicLink(result.public_link);
          this.setState({ fetch_loading: false });
        }
      })
      .catch(() => this.setState({ fetch_loading: false }));
  };

  register = () => {
    const { user, project, project_share, entity, artworks } = this.state;
    this.setState({ register_loading: true });

    let full_artworks =
      artworks.length > 0 ? this.artworkFiller(artworks) : artworks;

    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "register.json";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json"
      },
      body: JSON.stringify({
        user: user,
        entity: entity,
        project: project,
        artworks: full_artworks,
        project_share: project_share,
        viewingroom_api: true
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500) {
          this.setState({ errors: result.errors, register_loading: false });
        } else {
          this.setState(
            {
              user: result.user,
              public_link: result.public_link,
              register_loading: false
            },
            () => this.saveData()
          );
          this.props.history.push("/office");
        }
      })
      .catch(() => this.setState({ register_loading: false }));
  };

  removeArtwork = id => {
    const storedData = JSON.parse(window.localStorage.getItem("viewingroom"));
    const token = storedData && storedData.user.token;
    const email = storedData && storedData.user.email;

    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "delete_artwork.json";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json",
        "X-User-Token": token,
        "X-User-Email": email
      },
      body: JSON.stringify({
        id: id
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500) {
          this.setState({ errors: result.errors });
        }
      });
  };

  update = debounce(() => {
    const { user, project, project_share, entity, artworks } = this.state;

    this.setState({ update_loading: true });
    const storedData = JSON.parse(window.localStorage.getItem("viewingroom"));
    const token = storedData && storedData.user.token;
    const email = storedData && storedData.user.email;

    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "update_vr.json";
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json",
        "X-User-Token": token,
        "X-User-Email": email
      },
      body: JSON.stringify({
        user: user,
        entity: entity,
        project: project,
        artworks: artworks,
        project_share: project_share,
        viewingroom_api: true
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500) {
          this.setState({ errors: result.errors, update_loading: false });
        } else {
          // this.setState(result, () => this.simpleSave());
          // this.props.updatePublicLink(result.public_link);
          this.setState({ update_loading: false });
        }
      })
      .catch(() => this.setState({ update_loading: false }));
  }, 2000);

  artworkFiller = artworks => {
    artworks.forEach((a, index) => {
      if (!a.artist_name) {
        a["artist_name"] = "unknown";
      }
      if (!a.title) {
        a["title"] = "untitled";
      }
    });
    return artworks;
  };

  addArtwork = () => {
    const { artworks } = this.state;
    let newA = {
      local_index: artworks.length + 1,
      artist_name: "unknown",
      title: "untitled"
    };
    this.props.office
      ? this.saveNewArtwork(newA)
      : this.setState({
          artworks: [...this.state.artworks, newA]
        });
  };

  saveNewArtwork = newA => {
    this.setState({ new_artwork_loading: true });

    const { project, project_share, entity, artworks } = this.state;
    const storedData = JSON.parse(window.localStorage.getItem("viewingroom"));
    const token = storedData && storedData.user.token;
    const email = storedData && storedData.user.email;

    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "update_vr.json";
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json",
        "X-User-Token": token,
        "X-User-Email": email
      },
      body: JSON.stringify({
        entity: entity,
        project: project,
        artworks: [...artworks, newA],
        project_share: project_share,
        viewingroom_api: true,
        add_artwork: true
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500) {
          this.setState({ errors: result.errors, new_artwork_loading: false });
        } else {
          newA.id = result.id;
          this.setState(
            {
              artworks: [...this.state.artworks, newA]
            },
            () => this.simpleSave()
          );
          this.setState({ new_artwork_loading: false });
        }
      })
      .catch(() => this.setState({ new_artwork_loading: false }));
  };

  selectTemplate = template => {
    GAevent(this.props.office ? "User" : "Visitor", "Select Template");
    this.setState({ selected_template: template }, () => this.saveData());
  };

  updateProject = (key, value) => {
    GAevent(this.props.office ? "User" : "Visitor", "Update Project");
    const { project } = this.state;
    project[key] = value;
    this.setState({ project: project }, () => this.saveData());
  };

  updateProjectShare = (key, value) => {
    GAevent(this.props.office ? "User" : "Visitor", "Update ProjectShare");
    const { project_share } = this.state;
    project_share[key] = value;
    this.setState({ project_share: project_share }, () => this.saveData());
  };

  updateEntity = (key, value) => {
    GAevent(this.props.office ? "User" : "Visitor", "Update Entity");
    const { entity } = this.state;
    entity[key] = value;
    this.setState({ entity: entity }, () => this.saveData());
  };

  updateArtwork = (id, key, value) => {
    GAevent(this.props.office ? "User" : "Visitor", "Update Artwork");
    if (key === "price") {
      this.setState(
        prevState => ({
          artworks: prevState.artworks.map(a =>
            (this.props.office ? a.id : a.local_index) === id
              ? { ...a, price_cents: value * 100 }
              : a
          )
        }),
        () => this.saveData()
      );
    } else {
      this.setState(
        prevState => ({
          artworks: prevState.artworks.map(a =>
            (this.props.office ? a.id : a.local_index) === id
              ? { ...a, [key]: value }
              : a
          )
        }),
        () => this.saveData()
      );
    }
  };

  updateUser = (key, value) => {
    GAevent(this.props.office ? "User" : "Visitor", "Update User");
    const { user } = this.state;
    user[key] = value;
    if (key === "email") {
      this.updateEntity("email", value);
    }
    this.setState({ user: user });
  };

  deleteArtwork = id => {
    GAevent(this.props.office ? "User" : "Visitor", "Delete artwork");

    const { artworks } = this.state;

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this artwork!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        swal("Your artwork has been deleted!", {
          icon: "success"
        });
        let toDeleteIndex = artworks.findIndex(
          a => (this.props.office ? a.id : a.local_index) === id
        );
        if (toDeleteIndex > -1) {
          artworks.splice(toDeleteIndex, 1);
        }

        this.setState(artworks, () => this.saveRemove(id));
      } else {
        swal("Your artwork is safe!");
      }
    });
  };

  render() {
    const {
      artworks,
      entity,
      project,
      project_share,
      user,
      errors,
      public_link,
      register_loading,
      fetch_loading,
      update_loading,
      new_artwork_loading
    } = this.state;

    const { office } = this.props;
    if (fetch_loading) {
      return <Loader type="Bars" color="#000" height={40} width={80} />;
    }
    return (
      <Container office={office}>
        <TemplateSelector
          updateProjectShare={this.updateProjectShare}
          project_share={project_share}
          Wrapper={Wrapper}
          office={office}
          update_loading={update_loading}
        />
        <VRForm
          artworks={artworks}
          project_share={project_share}
          Wrapper={Wrapper}
          project={project}
          entity={entity}
          addArtwork={this.addArtwork}
          updateProject={this.updateProject}
          updateProjectShare={this.updateProjectShare}
          updateEntity={this.updateEntity}
          updateArtwork={this.updateArtwork}
          deleteArtwork={this.deleteArtwork}
          office={office}
          update_loading={update_loading}
          new_artwork_loading={new_artwork_loading}
        />
        {!office && (
          <Footer
            sticky={this.props.stickyFooter}
            user={user}
            entity={entity}
            project_share={project_share}
            updateUser={this.updateUser}
            updateEntity={this.updateEntity}
            updateProjectShare={this.updateProjectShare}
            register={this.register}
            errors={errors}
            public_link={public_link}
            register_loading={register_loading}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(ViewingRoomBuilder);
