import React from "react";

import styled from "styled-components";
import Modal from "react-modal";

import Close from "../assets/close.svg";

export function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
}

const ModalContent = styled.div`
  overflow: scroll;
  padding: 30px;
  @media (max-width: 414px) {
    padding: 15px;
  }
`;

const StyledReactModal = styled(ReactModalAdapter).attrs({
  modalClassName: "Modal"
})`
  & .ReactModal__Overlay {background-color: rgba(0, 0, 0, 0.7) !important;}
  & .Modal {
    position: absolute;
    top: 20%;
    left: 50%;
    right: 50%;
    bottom: 0%;
    margin-right: -50%;
    transform: translate(-50%, -15%);
    background: white;
    border-radius: 3px;
    padding: 20px;
    flex-direction: column;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    @media (max-width: 768px) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      margin: 0;
      transform: none;
    };
    &:focus {
      outline: none;
    }
  }
}`;

const CloseBtn = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

function PrivacyPolicy(props) {
  return (
    <StyledReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      ariaHideApp={false}
    >
      <CloseBtn
        src={Close}
        width="20px"
        onClick={props.onRequestClose}
        alt="X"
      />
      <ModalContent>
        {" "}
        <div>
          <h2>{props.entity_name} Privacy Policy</h2>
          <p>Your privacy is important to us.</p>
          <p>
            As a web-based service provider, we require some personal
            information in order to provide a service to you.
          </p>
          <p>
            However, we don’t ask for this information unless we truly need it.
          </p>
          <p>
            It is {props.entity_name}'s policy to respect your privacy regarding
            any information we may collect from you across our website.
          </p>
          <h3>Personal Information</h3>
          <p>We may ask you for personal information, such as:</p>
          <ul>
            <li>Your name</li>
            <li>Email address</li>
            <li>Age / date of birth</li>
            <li>Social media profile/s</li>
            <li>Contact details</li>
            <li>Company details, and</li>
            <li>Payment details</li>
          </ul>
          <p>This information is used for the purposes of: </p>
          <ul>
            <li>providing you with products and services</li>
            <li>
              providing you with information about our products and services
            </li>
            <li>
              managing and improving our products, services, quality control,
              administration, communication and internal processes
            </li>
            <li>personalising and customising your experience with us</li>
            <li>verifying your identity</li>
            <li>granting you access to your account with us</li>
            <li>
              investigating any enquiries or complaints submitted by or about
              you; and/or
            </li>
            <li>complying with regulatory or legal obligations</li>
          </ul>
          <p>
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </p>
          <p>
            We do not share your personal information with third-parties, except
            where required by law, to protect our own rights, or to provide a
            service to you.
          </p>
          <p>
            We will only retain personal information for as long as necessary to
            provide you with a service.
          </p>
          <h3>Business Data</h3>
          <p>
            Our respect for your privacy extends to the data you input into our
            website over the normal course of using our services. We will not
            share your data with third-parties, except where required by law, to
            protect our own rights, or to provide a service to you. We may,
            however, disclose anonymised and aggregated versions of this
            information for business, marketing or public relations purposes. We
            will only retain your data for as long as necessary to provide you
            with a service.
          </p>
          <h3>Cookies</h3>
          <p>
            We use "cookies" to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your computer, and accesses each time you visit so we can
            understand how you use our site and serve you content based on
            preferences you have specified.
          </p>
          <p>
            If you do not wish to accept cookies from us, you should instruct
            your browser to refuse cookies from our website, with the
            understanding that we may be unable to provide you with some of your
            desired service without them. This policy covers only the use of
            cookies between your computer and our website; it does not cover the
            use of cookies by any advertisers.
          </p>
          <h3>Security</h3>
          <p>
            We take security seriously, and do what we can within commercially
            acceptable means to protect your personal information from loss or
            theft, as well as unauthorized access, disclosure, copying, use or
            modification. That said, we advise that no method of electronic
            transmission or storage is 100% secure, and cannot guarantee the
            absolute security of your data.
          </p>
          <h3>Links to Other Sites</h3>
          <p>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot assume responsibility for their
            treatment of your personal information. This privacy policy only
            covers our website and privacy practices.
          </p>
          <h3>Children’s Privacy</h3>
          <p>
            We do not knowingly collect or store personal information from
            children (visitors under the age of 13). If you believe your child
            has provided us with personal information, we encourage you to
            contact us immediately, and we will do our best to delete the data
            as quickly as possible.
          </p>
          <h3>Changes to our Privacy Policy</h3>
          <p>
            At our discretion, we may change our privacy policy from time to
            time. Any changes will be reflected here, so we encourage you to
            visit this page regularly. We will also notify our registered users
            of updates to our policy. Your continued use of this site after any
            changes to this policy will be regarded as acceptance of our
            practices around privacy and personal information.
          </p>
          <h3>Business Transfers</h3>
          <p>
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include user
            information among our assets transferred to or acquired by a third
            party. You acknowledge that such transfers may occur, and that any
            parties who acquire us may continue to use your personal information
            according to this policy.
          </p>
        </div>
      </ModalContent>
    </StyledReactModal>
  );
}

export default PrivacyPolicy;
