import React, { Component } from "react";

import styled from "styled-components";
import Modal from "react-modal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Loader from "react-loader-spinner";

import { GApageView } from "../index";

import InlineText from "./inline_text";

import Close from "../assets/close.svg";

export function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
}

const ModalContent = styled.div`
  overflow: scroll;
  padding: 10px 30px;
  & a {
    text-decoration: none;
    border-bottom: 1px solid;
  }
  @media (max-width: 414px) {
    padding: 0 15px;
  }
  & h3 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 1em;
  }
`;

const StyledReactModal = styled(ReactModalAdapter).attrs({
  modalClassName: "Modal"
})`
  & .ReactModal__Overlay {background-color: rgba(0, 0, 0, 0.7) !important;}
  & .Modal {
    position: absolute;
    top: 20%;
    left: 50%;
    right: 50%;
    bottom: 0%;
    margin-right: -50%;
    transform: translate(-50%, -15%);
    background: white;
    border-radius: 3px;
    flex-direction: column;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    @media (max-width: 768px) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      margin: 0;
      transform: none;
    };
    &:focus {
      outline: none;
    }
  }
}`;

const CloseBtn = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const BlockContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    margin: 0;
  }
`;

const Hint = styled.div`
  font-size: 12px;
  & p {
    margin: 0;
  }
`;

const selectStyle = {
  fontWeight: 800,
  fontFamily: "inherit"
};

class GetLinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  componentDidMount() {
    GApageView("Registration Modal");
  }

  render() {
    const {
      Button,
      entity,
      user,
      project_share,
      updateEntity,
      updateUser,
      updateProjectShare,
      errors,
      register_loading,
      office_settings
    } = this.props;

    return (
      <StyledReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
      >
        <CloseBtn
          src={Close}
          width="20px"
          onClick={this.props.onRequestClose}
          alt="X"
        />
        <ModalContent>
          <div style={{ width: "fit-content" }}>
            <h3 style={{ background: "#FFF8E1" }}>Settings</h3>
          </div>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.props.register();
            }}
          >
            <>
              {!office_settings && (
                <>
                  <BlockContainer>
                    <h3>You</h3>
                    <div>
                      <InlineText
                        type="text"
                        value={user.first_name || ""}
                        placeholder={"First Name"}
                        onChange={e => updateUser("first_name", e.target.value)}
                        required
                        filled={user.first_name}
                        light
                      />
                      <InlineText
                        type="text"
                        value={user.last_name || ""}
                        placeholder={"Last Name"}
                        onChange={e => updateUser("last_name", e.target.value)}
                        required
                        filled={user.last_name}
                        light
                        side_side
                      />
                    </div>
                    <InlineText
                      type="email"
                      value={user.email || ""}
                      placeholder={"Email"}
                      onChange={e => updateUser("email", e.target.value)}
                      required
                      filled={user.email}
                      light
                      api_error={errors && errors["email"]}
                    />
                    <InlineText
                      type="password"
                      value={user.password || ""}
                      placeholder={"Password"}
                      onChange={e => updateUser("password", e.target.value)}
                      required
                      filled={user.password}
                      light
                      min={8}
                    />
                  </BlockContainer>
                  <BlockContainer>
                    <h3>
                      Your{" "}
                      <Select
                        value={entity.entity_type}
                        onChange={e =>
                          updateEntity("entity_type", e.target.value)
                        }
                        style={selectStyle}
                      >
                        <MenuItem value={"art_gallery"}>Gallery</MenuItem>
                        <MenuItem value={"collection"}>Collection</MenuItem>
                        <MenuItem value={"artist_studio"}>
                          Artist Studio
                        </MenuItem>
                        <MenuItem value={"institution"}>Institution</MenuItem>
                        <MenuItem value={"art_space"}>Space</MenuItem>
                      </Select>
                    </h3>

                    <InlineText
                      type="text"
                      value={entity.name || ""}
                      placeholder={"Name"}
                      onChange={e => updateEntity("name", e.target.value)}
                      required
                      filled={entity.name}
                      light
                    />
                  </BlockContainer>
                </>
              )}
              <BlockContainer>
                <h3>Project settings</h3>
                <SliderContainer>
                  <p>Require visitor email</p>
                  <Switch
                    checked={project_share.require_login || false}
                    onChange={e =>
                      updateProjectShare("require_login", e.target.checked)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </SliderContainer>
                <Hint>
                  <p>GDPR complient privacy policy provided</p>
                </Hint>
              </BlockContainer>
              <BlockContainer>
                <h3>Artworks settings</h3>
                <SliderContainer>
                  <p>Show prices</p>
                  <Switch
                    checked={project_share.show_price || false}
                    onChange={e =>
                      updateProjectShare("show_price", e.target.checked)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </SliderContainer>
                <SliderContainer>
                  <p>Show Inquire Button</p>
                  <Switch
                    checked={project_share.show_inquire || false}
                    onChange={e =>
                      updateProjectShare("show_inquire", e.target.checked)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </SliderContainer>
              </BlockContainer>
            </>
            {!office_settings && (
              <div
                style={{ height: 70, display: "flex", alignItems: "center" }}
              >
                {register_loading ? (
                  <Loader type="Bars" color="#000" height={40} width={80} />
                ) : (
                  <Button
                    style={{
                      background: "#000",
                      border: "2px solid #000",
                      color: "white"
                    }}
                    border
                    type="submit"
                  >
                    Get your viewing room link
                  </Button>
                )}
              </div>
            )}
          </form>
        </ModalContent>
      </StyledReactModal>
    );
  }
}

export default GetLinkModal;
