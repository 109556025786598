import React from "react";

import styled from "styled-components";

const Inline = styled.input`

  font-weight: ${props => (props.light ? 400 : 800)};
  border: none;
  font-size: 20px;
  padding: 5px 0;
  width: auto;
  border-radius: 0;
  &::placeholder {
    color: #c6c6c6;
  };
  &:focus {
    outline: ${props => (props.is_title ? "5px solid #fff8e1;" : "none")}
  }
  &:hover {
    background: #f6f9fc;
    ${props =>
      props.selected_template === "dark_mode" && "background: #494a4b;"}
  }
  ${props => !props.filled && "border-bottom: 1px dotted;"}
  @media (min-width: 768px) {
    ${props => props.side_side && "margin-left: 10px;"}
  }
  ${props =>
    props.is_title &&
    "font-size: 40px; width: 100%; margin-top: 30px; font-family: 'Akkurat Bold';"}
  ${props =>
    props.selected_template === "dark_mode" &&
    "background: #000; color: white;"}
  ${props =>
    ["title", "year", "medium"].includes(props.kind) && "font-weight: 400;"}

`;

const InlinePublic = styled.div`
  font-family: ${props => (props.light ? "Akkurat Light" : "Akkurat Bold")};
  border: none;
  font-size: 16px;
  padding: 1px 0;
  ${props =>
    props.is_title &&
    "font-size: 40px; width: 100%; margin-top: 30px; font-family: 'Akkurat Bold';"}
  ${props =>
    props.selected_template === "dark_mode" &&
    "background: #000; color: white;"}
  ${props =>
    ["title", "year", "medium"].includes(props.kind) && "font-weight: 400;"}
`;

const Error = styled.p`
  font-size: 14px;
  opacity: ${props => (props.red ? "1" : "0.4")};
  margin: 0;
  color: ${props => props.red && "#E53935"};
`;

function InlineText(props) {
  const validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  let error = null;
  if (props.value && props.type === "email" && !validateEmail(props.value)) {
    error = "Please provide a valid email address";
  }
  if (props.value && props.type === "password" && props.value.length < 8) {
    error = "Password must be at least 8 characters long";
  }

  const is_number =
    props.kind === "price" || props.kind === "year" ? true : false;

  if (props.public_room) {
    if (props.kind === "price" && !props.show_price) {
      return null;
    }
    if (props.value) {
      return (
        <InlinePublic
          is_title={props.is_title}
          light={props.light}
          style={props.style}
          kind={props.kind}
          selected_template={props.selected_template}
        >
          {props.value}
        </InlinePublic>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <Inline
        type={is_number ? "number" : props.type}
        value={props.value || ""}
        placeholder={props.placeholder}
        onChange={e => props.onChange(e)}
        required={props.required}
        disabled={props.disabled}
        is_title={props.is_title}
        selected_template={props.selected_template}
        filled={props.filled}
        kind={props.kind}
        light={props.light}
        style={props.style}
        side_side={props.side_side}
      />
      {error && <Error>{error}</Error>}
      {props.api_error && (
        <Error red>{props.placeholder + " " + props.api_error}</Error>
      )}
    </>
  );
}

export default InlineText;
