import React, { Component } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import Loader from "react-loader-spinner";

import VRForm from "../components/vr_form";
import VisitorAuth from "../components/visitor_auth";

import { GApageView } from "../index";

const Wrapper = styled.div`
  min-height: 100vh;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f6f6f6;
  height: 100vh;
  & h1 {
    font-size: 1em;
  }
`;

class PublicViewingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, error: false, visitor: {} };
  }

  componentDidMount() {
    GApageView("Public Viewing Room");
    let token = this.props.match.params.token;
    this.fetchPublicVR(token);

    const previous_visitor = JSON.parse(
      window.localStorage.getItem("viewingroom_visitor")
    );
    if (
      previous_visitor &&
      previous_visitor.vr_token &&
      previous_visitor.room_token === token
    ) {
      this.setState({ visitor: previous_visitor });
    }
  }

  fetchPublicVR = token => {
    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "public_viewing_room/" + token + ".json";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json"
      }
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500 || result.status === 204) {
          this.setState({ error: true, loading: false });
        } else {
          this.setState(result);
          this.setState({ loading: false });
        }
      })
      .catch(() => this.setState({ error: true, loading: false }));
  };

  updateVisitor = (key, value) => {
    const { visitor } = this.state;
    visitor[key] = value;
    this.setState({ visitor: visitor });
  };

  registerVisitor = () => {
    this.setState({ visitor_loading: true });
    const { entity, visitor } = this.state;
    const root_url = process.env.REACT_APP_ROOT_URL;
    const url = root_url + "register_visitor.json";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        dataType: "json"
      },
      body: JSON.stringify({
        visitor: visitor,
        entity_id: entity.id
      })
    })
      .then(res => res.json())
      .then(result => {
        if (result.status === 500) {
          this.setState({ error: true, visitor_loading: false });
        } else {
          this.setState(
            {
              visitor: result.visitor,
              visitor_loading: false
            },
            () => this.saveData()
          );
        }
      })
      .catch(() => this.setState({ error: true, visitor_loading: false }));
  };

  saveData = () => {
    let { visitor } = this.state;
    visitor.room_token = this.props.match.params.token;
    window.localStorage.setItem("viewingroom_visitor", JSON.stringify(visitor));
  };

  render() {
    const {
      artworks,
      project_share,
      project,
      entity,
      loading,
      error,
      visitor,
      visitor_loading
    } = this.state;
    if (loading) {
      return (
        <Container>
          <Loader type="Bars" color="#000" height={40} width={80} />
        </Container>
      );
    }
    if (error) {
      return (
        <Container>
          <h1>viewingroom.space</h1>
          <h3>Something went wrong...</h3>
          <Link to="/">Back to safety</Link>
        </Container>
      );
    }
    if (artworks && entity && project_share && project) {
      return (
        <VisitorAuth
          visitor={visitor}
          require_login={project_share.require_login}
          Container={Container}
          entity={entity}
          project={project}
          visitor_loading={visitor_loading}
          vr_token={visitor && visitor.vr_token}
          updateVisitor={this.updateVisitor}
          registerVisitor={this.registerVisitor}
        >
          <VRForm
            artworks={artworks}
            project_share={project_share}
            project={project}
            entity={entity}
            Wrapper={Wrapper}
            public_room
          />
        </VisitorAuth>
      );
    }
  }
}

export default PublicViewingRoom;
