import React, { useState } from "react";

import styled from "styled-components";
import Modal from "./get_link_modal";

import classic_template from "../assets/classic_template.svg";
import cover_template from "../assets/cover_template.svg";
import dark_template from "../assets/dark_template.svg";
import arrow_right from "../assets/arrow_right.svg";

const templates = [
  { name: "Classic", value: "classic", img: classic_template },
  { name: "Cover Picture", value: "cover_picture", img: cover_template },
  { name: "Dark Mode", value: "dark_mode", img: dark_template }
];

const Button = styled.button`
  background: ${props => (props.primary ? "white" : "none")};
  color: ${props => (props.primary ? "#313131" : "white")};
  font-size: ${props => (props.primary ? "1em" : "0.9em")};
  font-weight: ${props => props.primary && "bold;"};
  margin: ${props => (props.border ? "1em 0" : "1em")};
  padding: 0.65em 1em;
  border: ${props => (props.border ? "2px solid" : "none")};
  cursor: pointer;
  &:hover {
    ${props =>
      props.border &&
      "background: #313131; color: white; border: 2px solid #313131;"};
  }
  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & h3 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;
  }
`;

const Templates = styled.div`
  display: flex;
  flex: 2;
  justify-content: start;
  @media (max-width: 414px) {
    margin-top: 20px;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
  }
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  opacity: 0.4;
  margin-right: 15px;
  align-items: center;
  cursor: pointer;
  & img {
    width: 14px;
    margin-left: 7px;
    margin-bottom: 3px;
  }
  @media (max-width: 414px) {
    /* flex: 1; */
    display: flex;
    align-items: end;
    justify-content: flex-end;
    & p {
      font-size: 14px;
      margin-top: 0;
    }
  }
  ${props => props.selected && "opacity:1;"};
`;

const Temps = styled.div`
  display: flex;
  width: 100%;
`;

const TemplateSelector = props => {
  const { project_share, updateProjectShare, Wrapper } = props;
  const [openModal, setOpenModal] = useState(false);
  return (
    <Wrapper>
      <Container>
        {props.office ? (
          <h3 style={{ fontSize: "1.17em", marginBottom: "30px" }}>
            Edit your Viewing Room
          </h3>
        ) : (
          <h3>Start now</h3>
        )}
        {props.office && (
          <>
            <p
              style={{
                margin: 0,
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: 16,
                fontFamily: "Akkurat Bold"
              }}
              onClick={() => setOpenModal(true)}
            >
              <img
                src={arrow_right}
                width="13px"
                style={{ marginBottom: "-1px", marginRight: 3 }}
                alt="->"
              />
              Visitors and price settings
            </p>
            <Modal
              isOpen={openModal}
              onRequestClose={() => setOpenModal(false)}
              ariaHideApp={false}
              project_share={props.project_share}
              updateProjectShare={props.updateProjectShare}
              Button={Button}
              office_settings
            />
          </>
        )}
        <Templates>
          <TemplateContainer
            style={{
              display: "flex",
              alignItems: "start",
              opacity: 1
            }}
          >
            <p style={{ fontFamily: "Akkurat Bold", marginBottom: 8 }}>
              Template
            </p>
          </TemplateContainer>
          <Temps>
            {templates.map((t, index) => {
              return (
                <TemplateContainer
                  key={index}
                  selected={t.value === project_share.template}
                  onClick={() => updateProjectShare("template", t.value)}
                >
                  <img src={t.img} width="10px" alt={""} />
                  <p
                    style={
                      t.value === project_share.template
                        ? { borderBottom: "1px solid" }
                        : { borderBottom: "1px solid transparent" }
                    }
                  >
                    {t.name}
                  </p>
                </TemplateContainer>
              );
            })}
          </Temps>
        </Templates>
      </Container>
    </Wrapper>
  );
};

export default TemplateSelector;
